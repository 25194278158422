<template>
  <div class="component-container">
    <div class="content-container">
      <div class="card-list-component">
        <div class="list-header">
          <div class="list-header-name">账户列表</div>
          <div></div>
        </div>
        <div class="list-content card-list-content">
          <AccountList
            :accountId="accountId"
            :accountList="accountList"
            @handleClickCard="handleClickCard"
          />
        </div>
      </div>
      <div class="card-detail-component">
        <div class="list-header">
          <div class="list-header-name">账户详情</div>
        </div>
        <div
          class="list-content card-detail-content"
          v-loading="cardDetailLoading"
        >
          <div class="detail-year-conponent">
            <div class="date-picker-group">
              年：<el-button
                type=""
                icon="el-icon-d-arrow-left"
                @click="handleYearJump(-1)"
                :disabled="!accountId"
              ></el-button>
              <el-date-picker
                v-model="year"
                type="year"
                value-format="yyyy"
                :disabled="!accountId"
                @change="getAccountGeneralView"
                placeholder="选择年"
                style="width: 100px"
              />
              <el-button
                :disabled="!accountId"
                type=""
                icon="el-icon-d-arrow-right"
                @click="handleYearJump(1)"
              ></el-button>
            </div>
          </div>
          <div class="detail-total-conponent">
            <div class="detail-total">
              <div class="detail-total-type type-income">收入</div>
              <div class="detail-total-amount">
                {{ getDisplayAmount(total.income) }}
              </div>
            </div>
            <div class="detail-total-devide"></div>
            <div class="detail-total">
              <div class="detail-total-type type-spending">支出</div>
              <div class="detail-total-amount">
                {{ getDisplayAmount(total.spending) }}
              </div>
            </div>
          </div>
          <div class="detail-month-list-conponent">
            <div class="month-title">月度列表</div>
            <div class="month-list">
              <el-table
                :show-header="false"
                :data="monthList"
                :highlight-current-row="true"
                empty-text="请选择一个账户"
                class="month-list-table"
                width="90%"
                height="calc(100% - 10px)"
                @row-click="handleClickMonth"
              >
                <el-table-column prop="month" label="月" width="60">
                  <template slot-scope="{ row }"> {{ row.month }}月 </template>
                </el-table-column>
                <el-table-column prop="income" label="收入" align="right">
                  <template slot-scope="{ row }">
                    <span v-if="row.income !== '0'">
                      {{ getDisplayAmount(row.income) }}
                    </span>
                    <span v-if="row.income === '0'">-</span>

                    <span style="color: rgb(218, 10, 10); padding-left: 5px">
                      收
                    </span>
                  </template>
                </el-table-column>
                <el-table-column prop="spending" label="支出" align="right">
                  <template slot-scope="{ row }">
                    <span v-if="row.spending !== '0'">
                      {{ getDisplayAmount(row.spending) }}
                    </span>
                    <span v-if="row.spending === '0'">-</span>

                    <span style="color: rgb(37, 187, 7); padding-left: 5px">
                      支
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="s" width="50">
                  <template>
                    <i class="el-icon-arrow-right"></i>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="bill-list-component">
        <div class="list-header">
          <div class="list-header-name">账单列表</div>
        </div>
        <div class="list-content bill-list-content">
          <BillList
            v-loading="billListLoading"
            :accountId="accountId"
            :month="month"
            :billList="billList"
            :billTypeList="billTypeList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api";
import {
  spendingTypes,
  incomeTypes,
} from "../type-manage/component/defaultData.js";

import dayjs from "dayjs";

import AccountList from "@/components/AccountList/AccountList.vue";
import BillList from "./components/bill-list.vue";

import { getDisplayAmount } from "@/utils/display.js";

export default {
  name: "AccountCard",
  data() {
    return {
      accountList: [],

      year: null,
      month: null,
      accountId: "",

      billList: [],
      billListLoading: false,
      billTypeList: [],

      cardDetailLoading: false,
      monthList: [],
      total: {
        income: 0,
        spending: 0,
      },
    };
  },
  watch: {
    accountId: {
      handler() {
        ({
          month: this.month,
          billList: this.billList,
          monthList: this.monthList,
          total: this.total,
        } = this.$options.data());
      },
    },
    year: {
      handler() {
        ({
          month: this.month,
          billList: this.billList,
          total: this.total,
        } = this.$options.data());
      },
    },
  },
  components: {
    AccountList,
    BillList,
  },
  mounted() {
    this.getAccountList();
    this.getBillType();
    this.year = dayjs().format("YYYY");
  },
  methods: {
    getDisplayAmount,
    /**
     * 獲取所有賬戶
     */
    async getAccountList() {
      const res = await Api.AccountApi.queryAll();
      if (res && res.code === 0) {
        const { records } = res.data;
        this.accountList = records || [];
      } else {
        this.$message.error("获取所有账户失败");
      }
    },
    /**
     * 獲取所有的賬單類型
     */
    async getBillType() {
      this.billTypeList = [];
      const res = await Api.BillTypeApi.queryAll();
      if (res && res.code === 0) {
        const { records } = res.data;
        records.forEach((item) => (item.id = item._id));
        this.billTypeList = records || [];

        this.billTypeList.push(...JSON.parse(JSON.stringify(spendingTypes)));
        this.billTypeList.push(...JSON.parse(JSON.stringify(incomeTypes)));
      } else {
        this.$message.error("获取所有账单类型失败");
      }
    },
    /**
     * 點擊了賬戶
     * @param {string} cardId 賬戶的id
     */
    handleClickCard(cardId) {
      if (this.accountId) {
        if (this.accountId === cardId) {
          this.accountId = "";
        } else {
          this.accountId = cardId;
          this.getAccountGeneralView();
        }
      } else {
        this.accountId = cardId;
        this.getAccountGeneralView();
      }
    },

    /**
     * 获取某个账户的年度总览
     */
    async getAccountGeneralView() {
      const param = {
        date: this.year,
        accountId: this.accountId,
      };
      this.cardDetailLoading = true;
      const res = await Api.BillApi.getAccountGeneralView(param).finally(() => {
        setTimeout(() => {
          this.cardDetailLoading = false;
        }, 300);
      });
      if (res && res.code === 0) {
        const { data } = res;
        const { total, monthList } = data;
        this.monthList = monthList;
        this.total = total;
      } else {
        this.$message.error("年度总览查询失败");
      }
    },
    /**
     * 点击了月的行
     * @param {object} row 行数据
     * @param {object} column 列数据
     * @param {object} event 点击事件
     */
    handleClickMonth(row /** column, event */) {
      const { month } = row;
      this.month = month;
      this.getAccountMonthView();
    },
    /**
     * 获取某个账户的月度账单
     */
    async getAccountMonthView() {
      const param = {
        date: this.year + "-" + this.month, // "2024-03"
        accountId: this.accountId,
      };
      this.billListLoading = true;
      const res = await Api.BillApi.getAccountMonthView(param).finally(() => {
        this.billListLoading = false;
      });
      if (res && res.code === 0) {
        const { data } = res;
        const { records = [] } = data;
        this.billList = records;
      } else {
        this.$message.error("账单查询失败");
      }
    },
    handleYearJump(change) {
      let yearNumber = Number(this.year);
      if (!isNaN(yearNumber)) {
        yearNumber += change;
      }
      this.year = yearNumber + "";
      this.getAccountGeneralView();
    },
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  background-color: transparent;
  display: flex;
  > div {
    height: 100%;
    background-color: #fff;
    .list-header {
      background-color: rgb(246, 246, 246);
      height: 54px;
      width: 100%;
      border-bottom: 1px solid rgb(233, 233, 233);
      border-top: 1px solid rgb(233, 233, 233);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      .list-header-name {
        margin-left: 16px;
      }
    }
    .list-content {
      height: calc(100% - 54px - 2px);
      width: 100%;
    }
  }

  .card-list-component {
    width: 25%;

    .card-list-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .card-detail-component {
    width: 25%;
    border-left: 2px solid rgb(233, 233, 233);
    border-right: 2px solid rgb(233, 233, 233);
    .card-detail-content {
      .detail-year-conponent {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        .date-picker-group {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .detail-total-conponent {
        height: 130px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-image: linear-gradient(
            to right,
            rgb(252, 252, 252),
            rgb(252, 252, 252),
            rgb(207, 207, 207),
            rgb(207, 207, 207),
            rgb(207, 207, 207),
            rgb(207, 207, 207),
            rgb(207, 207, 207),
            rgb(207, 207, 207),
            rgb(207, 207, 207),
            rgb(252, 252, 252),
            rgb(252, 252, 252)
          )
          1;
        display: flex;
        align-items: center;
        justify-content: center;
        .detail-total {
          width: 49%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .detail-total-type {
            font-size: 20px;
            margin-bottom: 8px;
          }
          .detail-total-amount {
            margin-top: 8px;
            font-size: 24px;
            font-family: math;
          }
          .type-income {
            color: rgb(218, 10, 10);
          }
          .type-spending {
            color: rgb(37, 187, 7);
          }
        }
        .detail-total-devide {
          width: 1px;
          height: 65%;
          background: linear-gradient(
            rgb(228, 228, 228),
            rgb(192, 192, 192),
            rgb(228, 228, 228)
          );
        }
      }
      .detail-month-list-conponent {
        height: calc(100% - 200px - 2px);
        .month-title {
          font-size: 16px;
          height: 50px;
          padding-left: 40px;
          display: flex;
          align-items: center;
        }
        .month-list {
          overflow: hidden;
          height: calc(100% - 60px);
          display: flex;
          justify-content: center;
          width: calc(100% - 40px);
          margin: 0px auto;

          .month-list-table {
            cursor: pointer;
            border: 1px solid rgb(230, 230, 230);
            border-radius: 6px;
          }
        }
      }
    }
  }
  .bill-list-component {
    width: 50%;

    .bill-list-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
