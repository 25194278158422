<template>
  <div class="bill-list">
    <el-table
      :data="billList"
      height="100%"
      border
      class="bill-type-table"
      size="small"
      :empty-text="tableEmptyText"
    >
      <el-table-column prop="date" label="日期" width="110" align="center">
      </el-table-column>
      <el-table-column prop="time" label="时间" width="80" align="center">
      </el-table-column>
      <el-table-column prop="method" label="收支" width="85" align="center">
        <template slot-scope="{ row }">
          <el-tag v-if="row.method === 'spending'" type="success">
            支出
          </el-tag>
          <el-tag v-if="row.method === 'income'" type="danger"> 收入 </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="金额" width="90" align="right">
      </el-table-column>
      <el-table-column prop="billType" label="类型" width="180">
        <template slot-scope="{ row }">
          <BillTypeItem
            :billTypeId="row.billType"
            :billTypeList="billTypeList"
          />
        </template>
      </el-table-column>
      <el-table-column prop="description" label="详情" show-overflow-tooltip>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import BillTypeItem from "@/components/BillTypeSelector/BillTypeItem.vue";
export default {
  name: "BillList",
  components: { BillTypeItem },
  props: {
    accountId: {
      type: String,
      default: () => {
        return "";
      },
    },
    month: {
      type: String,
      default: () => {
        return "";
      },
    },
    billList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    billTypeList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    tableEmptyText() {
      let text = "";
      if (!this.accountId) {
        // 如果没有选择账户
        text = "请从左边选择一个账户";
      } else {
        // 选择了账户
        if (!this.month) {
          // 如果没有选择月份
          text = "请选择月份";
        } else {
          text = "暂无数据";
        }
      }
      return text;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.bill-list {
  width: calc(100% - 36px);
  height: calc(100% - 36px);
  .bill-type-table {
    width: 100%;
  }
}
</style>
